// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/shared/error/ServerError.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/shared/error/ServerError.tsx");
  import.meta.hot.lastModified = "1731188947069.7075";
}
// REMIX HMR END

import React from 'react';
const ErrorComponent = ({
  error
}) => {
  return <main className="error-page">
            <div className='error-content'>
                <img src="server-error.jpg" alt="Error" className="error-icon" />
                <h1>Ocurrió un error</h1>
                <p>Lo sentimos, ha ocurrido un error en el servidor.</p>
                <pre>{error?.stack}</pre>
                <p>Puedes intentar volver al <a href="/" className='error-link'>inicio</a>.</p>
            </div>
        </main>;
};
_c = ErrorComponent;
export default ErrorComponent;
var _c;
$RefreshReg$(_c, "ErrorComponent");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;